<template>
    <CRow>
        <CCol sm="12">
            <ListForm :headerTitle="$t('pages.supplier.supplierListing')" :onSearch="onSearch">
                <template v-slot:headerLeft>
                    <button type="button" name="add-addSupplier" class="btn btn-success" @click="onAddSupplier">
                        {{ $t('menu.addSupplier') }}
                    </button>
                </template>
                <template v-slot:searchBox>
                    <CForm>
                        <CRow>
                            <CCol md="6">
                                <CInput :label="$t('common.name')" :placeholder="$t('pages.supplier.enterSupplierName')"
                                        v-model="searchInfo.name" required horizontal>
                                </CInput>
                            </CCol>
                            <CCol md="6">
                                <CInput :label="$t('common.phone')" :placeholder="$t('common.enterPhoneNumber')"
                                        v-model="searchInfo.phone" required horizontal>
                                </CInput>
                            </CCol>
                            <CCol md="6">
                                <CInput :label="$t('common.email')" :placeholder="$t('common.enterEmail')"
                                        v-model="searchInfo.email" required horizontal>
                                </CInput>
                            </CCol>
                            <CCol md="6">
                                <CInput :label="$t('pages.supplier.certificate')" :placeholder="$t('pages.supplier.enterCertificate')"
                                        v-model="searchInfo.certificate" required horizontal>
                                </CInput>
                            </CCol>
                        </CRow>
                    </CForm>
                </template>
                <template v-slot:list>
                    <CDataTable :items="allSuppliers"
                                :fields="fields"
                                :noItemsView="{ noItems: $t('common.nodatafound') }"
                                hover
                                striped
                                border
                                small
                                fixed
                                :loading="isLoading"
                                pagination
                                :items-per-page="itemsPerPage"
                                @pagination-change="onItemsPerPageChange"
                                :items-per-page-select="{
                                label : $t('common.recordsperpage'),
                                values : [10,20,50,100] }">
                        <template #footer v-if="allSuppliers != null && allSuppliers.length > 0">
                            <tfoot>
                                <tr>
                                    <td :colspan="7">
                                        <span>{{ $t('common.totalRecords') }}: </span><span style="padding-left:5px;color:crimson;font-weight:bold">{{ totalItems }}</span>
                                    </td>
                                </tr>
                            </tfoot>
                        </template>
                        <template #supplier_detail_actions="{item, index}">
                            <td class="py-2">
                                <CButton color="primary"
                                         variant="outline"
                                         square
                                         size="sm"
                                         @click="onEditSupplier(item, index)">
                                    {{ $t('common.edit') }}
                                </CButton>
                                <CButton color="primary"
                                         variant="outline"
                                         square
                                         size="sm"
                                         @click="onDeleteSupplier(item, index)">
                                    {{ $t('common.remove') }}
                                </CButton>
                            </td>
                        </template>
                        <template #over-table>
                            <CPagination :activePage.sync="page"
                                         @update:activePage="onPageChange"
                                         :pages="totalPages"
                                         v-show="totalPages > 1"
                                         size="sm"
                                         align="center" />
                            Trang hiện tại: {{pageIndex + 1}}
                            Tổng số trang: {{totalPages}}
                        </template>

                        <template #under-table>
                            <CPagination :activePage.sync="page"
                                         @update:activePage="onPageChange"
                                         :pages="totalPages"
                                         v-show="totalPages > 1"
                                         size="sm"
                                         align="center" />
                            Trang hiện tại: {{pageIndex + 1}}
                            Tổng số trang: {{totalPages}}
                        </template>
                    </CDataTable>

                </template>
            </ListForm>
            <Confirmation ref="confirmation"></Confirmation>
        </CCol>
    </CRow>
</template>

<script>
    import i18n from '@/plugins/i18n'
    import Confirmation from '@/components/Confirmation.vue'
    import ListForm from '@/components/ListForm.vue'
    import {  mapActions, mapState } from 'vuex'

    export default ({
        name: 'Tables',
        data() {
            return {               
                itemsPerPage: 10,
                warningModal: false,                
                fields: [
                    { key: 'name', label: i18n.t('pages.supplier.supplierName') },
                    { key: 'certificate', label: i18n.t('pages.supplier.certificate') },
                    { key: 'phone', label: i18n.t('common.phone') },
                    { key: 'email', label: i18n.t('common.email') },
                    { key: 'address', label: i18n.t('common.address') },              
                    {
                        key: 'supplier_detail_actions',
                        label: i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
                ]
            };
        },
        components: { Confirmation, ListForm },
        computed: {
            //...mapGetters('supplier', ['isLoadingSuppliers', 'allSuppliers'])
            ...mapState('supplier', ['searchInfo', 'isLoading', 'allSuppliers', 'totalPages', 'pageIndex', 'totalItems']),
            page: {
                get() {
                    return this.pageIndex + 1;
                },
                set(newVal) {

                }
            },
        },
        methods: {
            ...mapActions('supplier', ['searchSuppliers']),
            onAddSupplier() {
                this.$router.push('/suppliers/add');
            },
            onDeleteSupplier(item) {
                this.$refs.confirmation.show(i18n.t('pages.supplier.confirmDelete'), async () => {
                    console.log('Delete Supplier', item);
                    var retVal = await this.$store.dispatch("supplier/deleteSupplier", item.id);
                    if (retVal == true) {
                        this.$store.dispatch("supplier/getAllSuppliers");
                    }
                });
            },
            onEditSupplier(item) {
                this.$router.push('/suppliers/edit/' + item.id);
            },
            onSearch() {
                this.searchSuppliers({ pageIndex: 0, pageSize: this.itemsPerPage });
            },
           
            onItemsPerPageChange(item) {
                this.itemsPerPage = item;
                localStorage.setItem('suppliersItemsPerPage', item)

                if (this.page > 0) {
                    this.searchSuppliers({ pageIndex: this.page - 1, pageSize: this.itemsPerPage });
                }
            },
            onPageChange(newPageNumber) {
                this.searchSuppliers({ pageIndex: newPageNumber - 1, pageSize: this.itemsPerPage });
            },
        },
        //mounted() {
        //    this.$store.dispatch("supplier/getAllSuppliers");
        //},
        created() {
            this.onSearch();

            let _iTemPerPage = parseInt(localStorage.getItem('suppliersItemsPerPage'));
            if (isNaN(_iTemPerPage))
                _iTemPerPage = 10;
            this.itemsPerPage = _iTemPerPage;
        }
    })
</script>
